import "./product-description-tooltip.scss"

import React from "react"

import IcoProductInfoTooltip from "assets/images/ico-product-info-tooltip.svg"

const ProductTooltip = ({ type }) => {
  return (
    <div className="product-description-tooltip">
      <button>
        <img src={IcoProductInfoTooltip} alt="" />
      </button>
      <div className="product-description-tooltip__wrapper">
        <div className="product-description-tooltip__inner">
          <p>
            {type === "N"
              ? "N - Laptop fabrycznie nowy"
              : type === "A+"
              ? "A+ - Laptop używany w stanie idealnym, wygląda jak nowy."
              : type === "P"
              ? "Powystawowy - laptop pochodzi z nadwyżek magazynowych producenta."
              : type === "A"
              ? "A - Laptop używany może posiadać minimalne ślady użytkowania, matryca w stanie idealnym."
              : type === "A-"
              ? "A- - Laptop używany posiada widoczne ślady użytkowania (delikatne rysy na klapie, małe wytarcia na obudowie lub klawiaturze)."
              : type === "A-*"
              ? "A-* -Gwiazdka przy opisie klasy oznacza minimalne ślady na matrycy (duszki), które nie przeszkadzają w codziennym użytkowaniu."
              : type === "B+"
              ? "Klasa B+ niewielkie ślady użytkowania"
              : type === "B"
              ? "B - Laptop używany, będący kompromisem między wysoką jakością, a ceną. Ze względu na ślady użytkowania oferta jest przeceniona. (Rysy, wytarcia, plamki, przejaśnienia, ryski matrycy - każda wada jest stosowanie opisana)"
              : ""}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProductTooltip
